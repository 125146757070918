<template>
  <v-app v-if="loading">
    <v-main>
      <!-- <div
        v-if="domaintrue"
        style="
          background-color: #005672;
          color: white;
          padding: 10px 50px;
          font-size: 16px;
        "
      >
        <div>
          You have reached to the {{ countryname }} Site of
          <span style="text-transform: capitalize">{{ searchDomaintitle }}</span
          >. Continue to the {{ visitcountryname }} Site at
          <span
            @click="routerDomain"
            style="border-bottom: 1px solid; cursor: pointer; font-weight: 500"
          >
            {{ dataDomain }}
          </span>
        </div>
      </div> -->
      <!-- <div v-if="loading"> -->
      <div v-if="datashow">
        <div v-if="whatsappApi">
          <a
            :href="whatsappApi"
            class="whatsapp-float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon class="whatsappImg" size="40">mdi-whatsapp</v-icon>
          </a>
        </div>
        <div id="navbar">
          <headerCom1 @localChanged="updateLocale"/>
        </div>
        <div class="bg-pages">
          <router-view />
        </div>

        <div id="food">
          <component :is="selectedFooter" />
        </div>
      </div>
      <!-- </div> -->
      <!-- <v-dialog v-model="dialog1" persistent>
      <div class="d-flex justify-center">
        <v-card class="p-4">
          <h6>Oops! Site is being updated. <br/>Sorry, for the inconvenience. We will be right back with exciting new features. 
           <br/> Please visit again after some time.</h6>
        </v-card>
      </div>
  </v-dialog> -->
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import headerCom1 from "@/components/headerCom.vue";
// import aboutUs from "@/components/aboutUs.vue";
import country1 from "@/coundtrycode.json";
import CryptoJS from "crypto-js";
import moment from "moment";

import footerCom from "@/components/footerCom.vue";
// import footerCom1 from "@/components/footerCom1.vue";
// import footerCom2 from "@/components/footerCom2.vue";
// import footerCom3 from "@/components/footerCom3.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  name: "App",
  components: {
    headerCom1,
    // aboutUs,
    dataDomain: "",
    dataDomainData: "",
    SearchDomain: "",
    searchDomaintitle: "",
    // headerCom,
    // headerCom2,
    // headerCom3,
    domaintrue: false,
    footerCom,
    // footerCom1,
    // footerCom2,
    // footerCom3,
    dialog1:false,
  },
  data: () => ({
    navbar: true,
    food: true,
    datashow: false,
    countryname: "",
    selectedHeader: "headerCom1",
    selectedFooter: "footerCom",
    loading: true,
    visitcountryname: "",

     countryOptions: [],
    siteContentData: "",
    languageData: "",
    getLanguages: "",
    countyIsocode: "",

    configReq: {
      service_type: "GetPortalConfigRQ",
      api_ver: "1",
      payload: {
        domain_name: "",
        env: "CERT",
        language: "EN",
        token: "jsdjOJDKJ7675",
      },
    },

    configUser_Id: "",
    tawktoApi:"",
    i18nlocaleLang:"",
    getEndpoint:"",
    loaderReq:{
      "payload": {
        "domain": "",
        "etoken": "d9781f79c8e577d068587e0aca400e28"
      }
    },
    loaderApi:"https://otaget.nexus2.wistirna.com",
    whatsappApi:"",
  }),

  methods: {
    updateLocale(newLocale) {
      // Update the app's locale based on the event received from headerCom1.vue
      this.i18nlocaleLang = newLocale;
      console.log('App locale updated to:', this.i18nlocaleLang);
    },
    indexHeader() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;

        let pluginTawkto = "";
        pluginTawkto = getConfigData.payload.portal_configuration.menus.enabled.plugins;
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')

        if(pluginTawkto.includes("whatsapp")){
          this.whatsappApi = getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
        }
        else{
          this.whatsappApi = "";
        }
        // console.log(this.whatsappApi,'whatsappAPiiii')

        if (pluginTawkto.includes("tawkto")) {
          this.tawktoApi = getConfigData.payload.portal_configuration.API_endpoints.tawkto;
          // console.log(this.tawktoApi, " this.tawktoApithis.tawktoApi ");
        } else {
          this.tawktoApi = "";
        }

        

        console.log(this.languageData, 'siteContentDatasiteContentDatasiteContentData')

        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;

        // TawkTo code
        const s1 = document.createElement("script");
        const s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = this.tawktoApi;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      }
    },
    async getloaderPortal(){
      let hostname = window.location.hostname;
      // let domainname  = window.location.origin;

      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.loaderReq.payload.domain = hostname;
      }

      let Key = "8dsxBc9vDl4I9alHbJU7Dg4EBbr7AS0w";
      let domain_name = this.loaderReq.payload.domain;
      console.log(domain_name,'domain_nameeeee')
      let current_date = moment(new Date()).format("YYYYMMDD");
      let fixed_value = 3;

      // console.log(current_date,'dasafsdfdasdasd');

      let compineskey = Key + domain_name + current_date + fixed_value.toString();
      const encryptedkey = CryptoJS.MD5(compineskey).toString();

      this.loaderReq.payload.etoken = encryptedkey

      // console.log(this.loaderReq,'loderred...2');

      await axios.post(this.loaderApi,this.loaderReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.data.response_type === "success"){
            this.getEndpoint = response.data.endpoint;

            if(this.getEndpoint){
              this.envPortal();
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching application:", error);
        });
    },

    envPortal() {
      let hostname = window.location.hostname;

      console.log(hostname, "hshshshs....")
      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.configReq.payload.domain_name = hostname;
      }

      console.log(this.configReq, "configReqconfigReqconfigReq");

       axios.get(`${this.getEndpoint}getportalconfig`, {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          if (response) {
            this.loading = true;
            let configResponse = response.data;
            console.log(configResponse, "configResponseconfigResponse");
            this.dataDomainData =
              configResponse.payload.portal_configuration.geoip_location.country_domain.replace(
                "https://",
                ""
              );
            this.dataDomain =
              this.dataDomainData.charAt(0).toUpperCase() +
              this.dataDomainData.slice(1);
            this.SearchDomain = this.configReq.payload.domain_name;
            this.searchDomaintitle =
              configResponse.payload.portal_configuration.site_title;
            this.countryname =
              configResponse.payload.portal_configuration.country.name;
            let $dataname =
              configResponse.payload.portal_configuration.geoip_location
                .country_iso_code;
            // console.log(this.dataDomain,this.SearchDomain, 'response.data')

             this.getLanguages = configResponse.payload.portal_configuration.languages;
             this.countryOptions = configResponse.payload.portal_configuration.country_options;

            this.languageData = configResponse.payload.portal_configuration.language_data;

            this.countyIsocode = configResponse.payload.portal_configuration.country.iso_code;

            if (this.languageData) {
              this.getlangaugeUrl();
              // this.localeLanguage();
            }
            else{
              this.$i18n.locale = 'CA-en';
            }

            if (this.getLanguages && this.countyIsocode) {
              this.setLanguage();
              console.log('working...1')
              // this.$i18n.locale = `${this.countyIsocode}-${this.getLanguages[0].code}`
              // console.log(this.$i18n.locale, "langresponseresponse...1");
            }
            else{
              this.$i18n.locale = 'CA-en';
              console.log('working...2')
              // this.$i18n.locale = `${this.countyIsocode}-${this.getLanguages[0].code}`
            }

            country1.forEach((v) => {
              if ($dataname == "US" || $dataname == "CA") {
                if (v.code == $dataname) {
                  this.visitcountryname = v.name;
                }
              } else {
                this.visitcountryname = "Global";
              }
            });

            // console.log(t$data,this.SearchDomain,'this.dataDomain')
            if (this.dataDomainData.includes(this.SearchDomain)) {
              console.log("pppppppaduhdh");
              this.domaintrue = false;
            } else {
              this.domaintrue = true;
            }

            // Encrypt data using CryptoJS
            const secretKey = "portal_config_datas";
            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(configResponse),
              secretKey
            ).toString();

            localStorage.setItem("portalData", encryptedData);

            // localStorage.setItem("portalData", JSON.stringify(configResponse))

            if (configResponse) {
              this.datashow = true;
            }
            this.indexHeader();
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          this.dialog1 = true;
          alert("Oops! Site is being updated. Sorry, for the inconvenience. We will be right back with exciting new features. Please visit again after some time.");
          this.loading = false;
        });
    },

    // getRandomChars(length) {
    //   let result = "";
    //   for (let i = 0; i < length; i++) {
    //     const randomIndex = Math.floor(Math.random() * this.strResult.length);
    //     result += this.strResult[randomIndex];
    //   }
    //   console.log(result, "result");
    //   return result;
    // },
    // getRandomInt(min, max) {
    //   return Math.floor(Math.random() * (max - min + 1)) + min;
    // },

    // generatePortalConfig_id() {
    //   const randomChars1 = this.getRandomChars(3);
    //   const randomNum = this.getRandomInt(10, 99);
    //   const randomChars2 = this.getRandomChars(3);

    //   this.configUser_Id = randomChars1 + randomNum + randomChars2;
    //   console.log(this.configUser_Id, 'configUser_IdconfigUser_Id')
    // },

    // async envPortal() {
    //   try {
    //     const response = await axios.get("https://dl.example.com/api/getportalconfig", {
    //       params: this.configReq,
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     });

    //     if (response) {
    //       let configResponse = response.data;
    //       console.log(configResponse, 'sdfdhfiadfadh');
    //       localStorage.setItem('portalData', JSON.stringify(configResponse));
    //       this.indexHeader();
    //     }
    //   } catch (error) {
    //     console.log("Error fetching data:", error);
    //     alert('Something went wrong...');
    //   }
    // },
        setLanguage() {
      let getLang = this.getLanguages[0].code;
      let savedLanguage = `${this.countyIsocode}-${getLang}`;
      localStorage.setItem("setlang", savedLanguage);
      // let setlocalLang = localStorage.setItem("setlang", savedLanguage);
      // if(setlocalLang){
      //   this.localeLanguage();
      // }
    },localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";


      if (storedLocale) {
        getlocalIso = storedLocale.split('-')[0];
        getlocalLang = storedLocale.split('-')[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split('-')[0];
        getsetlang = storedSetLang.split('-')[1];
      }


      if (storedSetLang && storedLocale) {
        console.log('Check...1');
        if (storedLocale == storedSetLang) {
          console.log('Check...2');

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        }
        else if (storedLocale !== storedSetLang) {
          console.log(getlocalIso, getsetIso, 'Check...3', getlocalLang, getsetlang);
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang) {

            localStorage.removeItem("defaultlang");
            console.log('Check...4');
            return storedSetLang;
            // this.i18n.locale = storedSetLang;

          }
          else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log(storedLocale, 'Check...5');

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      }
      else if (storedSetLang) {
        console.log('Check...6');

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      }
      else if (storedLocale) {
        console.log('Check...7');

        return storedLocale;
        // this.i18n.locale = storedLocale;
      }
      else {
        console.log('Check...8');

        return 'CA-en';
        // return `${this.countyIsocode}-${this.getLanguages[0].code}`;

        // this.i18n.locale = `${this.countyIsocode}-${this.getLanguages[0].code}`;
      }
    },


    getlangaugeUrl() {

      let langData = this.languageData
      // console.log(langData, 'languageDatalanguageDatalanguageData...1');
      // let getlocale = this.localeLanguage();
      // this.$i18n.locale = this.localeLanguage();
      var localLang = this.localeLanguage(); //CA.es

      // console.log(localLang, 'languageDatalanguageDatalanguageData...2');

      let langdataUrl = "";
      // console.log(localLang,'localLanglocalLang')
      Object.keys(langData).forEach(v => {
        console.log(v,this.countyIsocode, 'languageDatalanguageDatalanguageData...3');
        if (v == this.countyIsocode) {
          const lang = langData[v];

          Object.keys(lang).forEach(local => {
            // console.log(local, 'dasasasasdas')
            if (local == localLang) {

              langdataUrl = lang[local]
              console.log(local, localLang, langdataUrl, 'dasasasasdas...2');

              axios.get(langdataUrl, {
                headers: {
                  "Content-Type": "text/html; charset=utf-8",
                },
              })
                .then((response) => {
                  if (response) {
                    // this.aboutContent = response.data
                    // console.log(response, "langresponseresponse...2");
                    // console.log(response.data, "langresponseresponse");
                    const cleanedData = this.parseLanguageData(response.data);
                    this.setLanguageMessages(localLang, cleanedData);
                    // setInterval(() => {
                    //   location.reload();
                    // }, 500);
                    // this.dataloader = false;
                  }
                  // else {
                  //   this.$i18n.locale = `${this.countyIsocode}-${this.getLanguages[0].code}`
                  //   console.log(this.$i18n.locale, "langresponseresponse...1");
                  // }
                })
                .catch((error) => {
                  console.log(error, 'errorrLangaugeAPI.....')

                  this.$i18n.locale = 'CA-en'
                  // this.dataloader = true;
                })
            }
          })
        }
      });
    },


    parseLanguageData(data) {
      try {
        const cleanedData = data
          .replace(/export\s+default\s+/, '')
          .replace(/;\s*$/, ''); // Remove trailing semicolon if present
        return eval(`(${cleanedData})`);
      } catch (error) {
        console.error('Error processing language content:', error);
        return {};
      }
    },

    setLanguageMessages(locale, messages) {
      this.$i18n.setLocaleMessage(locale, messages);
      this.$i18n.locale = locale;

      console.log(this.$i18n.locale,'setLanguageMessagessetLanguageMessages')
    }
  },
  mounted(){
    this.getloaderPortal();
  },

  // created() {
  //   this.envPortal();
  //   // this.getRandomChars();

  //   setTimeout(() => {
  //     // this.indexHeader();
  //   }, 2000);
  // },

  watch: {
    "i18nlocaleLang": {
      immediate: true,
      handler: function () {
        // this.localeLanguage();
        this.getlangaugeUrl();
      },
      // deep: true,
    },
    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.getloaderPortal();
        if (newValue === "/loader") {
          this.navbar = false;
          this.food = false;
        } else {
          this.navbar = true;
          this.food = true;
        }
        // console.log(newValue, '4545');
      },
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&family=Poppins:wght@300;400;500&display=swap");

/* .bg-pages {
background: rgb(230, 217, 26);
background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193));
} */

/* * {
  font-family: 'Poppins', sans-serif;
} */

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

.bg-pages {
  background: #f1f1f1;
  /* background-image:url('./assets/bmbg2.svg'); */
}
.whatsapp-float {
  position: fixed;
  width: 55px;
  height: 52px;
  bottom: 115px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  box-shadow: 1px 1px 2px #888;
}
v-icon:hover{
  color:white;
}
.whatsapp-float:hover{
  color:white;
}
 a:visited{
  color:white !important;
 }
/* .whatsapp-float img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
 .whatsappImg {
   width: 45px;
  height: 45px;
 }
.whatsappImg:hover{
  color:white;
}
.v-overlay-container .v-overlay__content {
  min-width:0 !important;
}
/* .bg-pages{
    background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);
  } */
/* .bg-pages { */

/* } */
</style>
