<template>

    <div class="row d-flex flex-column align-center py-5" v-if="ResetBox">
        <h4 class="text-center fw-bold mb-4 d-none">LOGIN</h4>
        <div class="col-lg-6 d-flex flex-column align-center" style="position: relative; z-index: 0;">
            <v-card outlined style=" width:80%; border-radius:7px;box-shadow: 0px 0px 8px 0px black; " >
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-12 main-page">
                        <div class="bg-light main-page mx-auto p-3"
                            style="background-color:#f8b2b2; width: 100%; height: auto;">
                            <!-- <div class="col-12 pe-1 pt-1 d-flex justify-end" @click="closeLoginBox()">
                                <v-icon style="color:navy; cursor:pointer" size="small">mdi-close-circle</v-icon>
                            </div> -->
                            <div class="d-flex align-center w-100">
                                <div class="m-2 ms-0 rounded-circle lockIcon d-flex align-center justify-center">
                                    <i class="bi bi-key fs-2 p-4"></i>
                                </div>
                                <div>
                                    <h4 class="m-0">{{ $t("newPasswordContents.headings.createPass") }}</h4>
                                    <p class="m-0 f-size-12">{{ $t("newPasswordContents.headings.subText1") }}</p>
                                </div>
                            </div>

                            <v-form ref="form" class="py-3 w-100" v-if="ResetBox" v-model="valid" @submit.prevent="resetSubmit">

                                <v-text-field v-model="resetDetails.newPassword" :rules="uPass" :label="this.$t('newPasswordContents.labels.newPassword')"
                                    placeholder="Enter New password" variant="outlined" required
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"></v-text-field>

                                <v-text-field v-model="resetDetails.conPassword" :rules="uPass" :label="this.$t('newPasswordContents.labels.confirmPassword')"
                                    placeholder="Enter Confirm password" variant="outlined" required
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"></v-text-field>


                                <div class="d-flex mt-3">
                                    <div class="col-9 d-flex justify-end">
                                        <v-btn style="background-color:#162849; color:white" width="60%"
                                            :disabled="btnDisabled" type="submit">{{ $t("newPasswordContents.buttons.reset") }}</v-btn>
                                    </div>
                                    <div class="col-3 ps-1 d-flex justify-end">
                                        <v-progress-circular v-if="loadRest" :width="3" style="color:navy"
                                            indeterminate></v-progress-circular>
                                    </div>
                                </div>
                            </v-form>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </div>



    <div v-if="!ResetBox" class="d-none">
        <div class="trip">
            Create Your Password
        </div>

        <v-form v-model="valid" ref="form" @submit.prevent="resetSubmit">
            <div class="pre-card">
                <v-card style="width: 550px;padding: 30px;">
                    <div class="col-12">
                        <v-text-field v-model="resetDetails.newPassword" :rules="uPass" label="New Password"
                            placeholder="Enter New password" variant="outlined" required
                            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                            style="height:60px;font-size: 15px !important"></v-text-field>
                    </div>

                    <div class="col-12">
                        <v-text-field v-model="resetDetails.conPassword" :rules="uPass" label="Confirm Password"
                            placeholder="Enter Confirm password" variant="outlined" required
                            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                            style="height:60px;font-size: 15px !important"></v-text-field>
                    </div>

                    <div class="d-flex mt-3">
                        <div class="col-9 d-flex justify-end">
                            <v-btn style="background-color:#162849; color:white" width="60%" :disabled="btnDisabled"
                                type="submit">Reset</v-btn>
                        </div>
                        <div class="col-3 ps-1 d-flex justify-end">
                            <v-progress-circular v-if="loadRest" :width="3" style="color:navy"
                                indeterminate></v-progress-circular>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-form>
    </div>
</template>


<script>
import axios from 'axios'

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {

    data() {
        return {
            ResetBox: false,
            terms: false,
            visible: false,
            loadRest: false,
            btnDisabled: false,

            loginshow: true,
            resetshow: false,


            resetDetails: {
                newPassword: "",
                conPassword: "",
            },

            eMail: [
                v => !!v || 'Please enter your email',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            uPass: [
                v => !!v || 'Please enter your Password'
            ],

            getId: "",
            getMail: "",

            userRequest: {
                "request_type": "reset_password",
                "email": "irwogms@gmail.com",
                "enkey": "qt3cU5nWjV2OPeQBEYkwD4adr8oy9I",
                "user_id": "736473",
                "portal_id": "port8787"
            },

            resetRequest: {
                "request_type": "change_password",
                "new_password": "ekfjhury84r8",
                "confirm_password": "ekfjhury84r8",
                "email": "irwogms@gail.com",
                "user_id": "736473",
                "portal_id": "port8787"
            },

            checkPassword: "",
            updatePassword: "",
        }
    },
    methods: {


        checkingReset() {
            this.userRequest.enkey = this.getId
            this.userRequest.email = this.getMail

            console.log(this.userRequest, 'vvvvaaaallll')

            axios.post(this.checkPassword, this.userRequest, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

                .then(response => {
                    console.log(response, "dfsdvdff")
                    if (response.data.reset_password_response == 'success') {
                        this.ResetBox = true
                    }
                    else if (response.data.reset_password_response == 'fail') {
                        this.ResetBox = false
                        this.$router.push('/')
                    }
                    else {
                        this.ResetBox = false
                        this.$router.push('/')
                    }
                })
                .catch(errors => {
                    console.log(errors, "eeeerrrrroooossssss......")
                    this.ResetBox = false
                    this.$router.push('/')
                })

        },


        resetSubmit() {
            if (this.valid) {
                this.resetRequest.email = this.getMail
                this.resetRequest.new_password = this.resetDetails.newPassword
                this.resetRequest.confirm_password = this.resetDetails.conPassword

                console.log(this.resetRequest, 'resetRequest:....')

                axios.post(this.updatePassword, this.resetRequest, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                    .then(response => {
                        console.log(response, "response for reset pass.....")
                    })
                    .catch(errors => {
                        console.log(errors, "dfsdvdff")
                    })
            }
        },

        getConfig() {
            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData1 = JSON.parse(portalLocal)
            const getConfigData1 = getConfigDataFromLocalStorage();
            if (getConfigData1) {
                this.checkPassword = getConfigData1.payload.portal_configuration.API_endpoints.check_password
                this.updatePassword = getConfigData1.payload.portal_configuration.API_endpoints.update_password
            }
        },
    },

    created() {
        this.getConfig();

        this.getId = this.$route.query.enkey
        this.getMail = this.$route.query.email
        console.log(this.getId, this.getMail, 'gggggIIiiddi')

        this.checkingReset()
    }




}
</script>



<style scoped>

.lockIcon{
    background-color: #F8A21A;
    height: 50px;
    width: 50px;
}

.trip {
    justify-content: center;
    display: flex;
    background-color: #f3d8cf;
    padding: 30px;
    font-size: 25px;
    font-weight: 600;
}

.pre-card {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    border-radius: 15px;
}

/* Login Page */

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
    background-color: transparent;
    margin: 5px;
    padding: 2px;
    box-shadow: none;
}

.btn-active {
    background-color: blueviolet;
    color: white;
}

.v-checkbox .v-input__details {
    display: none;
}

>>>.v-label--clickable {
    cursor: pointer;
    font-size: 12px;
}

>>>.v-input__details {
    display: block !important;
}

.forgotStyle {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 1px;
    color: #0D6EFD;
    text-decoration: underline;
    cursor: pointer;
}

/* Login Page End */



@media only screen and (max-width: 992px) {

    .form-btn {
        padding: 10px 0px 0px 0px !important;
        /* color: white; */
    }

    .btn-box,
    .pipe {
        color: blueviolet !important;
    }

    .btn-active {
        background-color: blueviolet;
        color: white !important;
    }
}
</style>
